<template>
  <div>
    <div class="wrap">
      <div class="con_top">
        <h2><span>■</span> 공지사항</h2>
      </div>
      <div class="con">
        <div class="con_table">
          <div class="funcBtns">
            <div class="float-right dp-block mb-20">
              <a class="btn_new2 ml-10 pointer" v-if="isAdminView" @click="moveToNoticeRegister()">등록<span></span></a>
            </div>
          </div>

          <table style="margin-bottom:0px;" class="table-row table-serach">
            <tbody>
              <tr>
                <th width="180">기간</th>
                <td>
                  <DatePicker mode class="w-110px float-left" v-model="param.sdate">
                    <template #default="{ inputValue, togglePopover }">
                      <div style="display:flex;">
                        <input style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;" type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                        <button class="btn-cal pointer" @click="togglePopover()"></button>
                      </div>
                    </template>
                  </DatePicker>

                  <span class="float-left ml-10 mr-10">~</span>

                  <DatePicker class="w-110px float-left" v-model="param.edate">
                    <template #default="{ inputValue, togglePopover }">
                      <div style="display:flex;">
                        <input style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;" type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                        <button class="btn-cal pointer" @click="togglePopover()"></button>
                      </div>
                    </template>
                  </DatePicker>
                </td>
                <th width="180">작성자</th>
                <td width="500">
                  <input @keyup.enter="handleSearch()" type="text" v-model="param.staffName" class="w-100per" />
                </td>
              </tr>
              <tr>
                <th width="180">제목</th>
                <td colspan="1">
                  <input @keyup.enter="handleSearch()" type="text" v-model="param.value" class="w-100per" />
                </td>
                <th width="180">내용</th>
                <td colspan="1">
                  <input @keyup.enter="handleSearch()" type="text" v-model="param.boardContents" class="w-100per" />
                </td>
              </tr>
            </tbody>
          </table>

          <div class="funcBtns">
            <div class="float-right dp-block mb-20">
              <a class="btn_new2 ml-10 pointer" style="top:20px;" @click="handleSearch()">검색<span></span></a>
            </div>
          </div>
          <div class="clear"></div>

          <table class="default_table mt-20">
            <tbody v-if="loading">
              <tr>
                <th class="w-50px">No</th>
                <th>제목</th>
                <th class="w-150px">작성자</th>
                <th class="w-150px">최종 수정일</th>
              </tr>
              <tr>
                <td colspan="8">불러오는중..</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <th class="w-50px">No</th>
                <th>제목</th>
                <th class="w-150px">작성자</th>
                <th class="w-150px">최종 수정일</th>
              </tr>
              <tr v-for="(item, index) of boardList" :key="index">
                <td>{{ item.rownum }}</td>
                <td>
                  <span class="pointer underline" @click="moveToNoticeDetail(item.idx)">{{ item.board_title }}</span>
                  <!-- 댓글 있는 글에 표시되는 icon -->
                  <img v-if="item.commentCnt" src="@/assets/images/re_icon.png" style="margin-left:10px; height : 18px;" />
                </td>
                <td>{{ item.kname }}</td>
                <td>{{ item.board_updatedate }}</td>
              </tr>
              <tr v-if="boardList.length === 0">
                <td colspan="8">검색 결과가 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <div class="con_table">
            <div class="page">
              <ul>
                <li>
                  <a class="pointer" @click="prevPaging()"> &lt; </a>
                </li>
                <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                  <span class="pointer" style="padding: 8px;" @click="paging(index)">
                    <a :class="{ bold: index === page }">{{ index }}</a>
                  </span>
                </li>
                <li>
                  <a class="pointer" @click="nextPaging()"> &gt; </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import store from '@/store/index';

export default {
  components: {
    DatePicker,
  },
  name: "HappycrmFrontNotice",

  data: () => ({
    boardList: [],
    page: 1,
    pagesize: 50,
    // 페이징에 필요한 변수들
    listLength: 0, // 리스트 size
    maxPagingCount: 1, // 최대 페이지 수
    maxPagePart: 1, // 최대 파트가 몇번째인지
    pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
    loading: false,
    param: {
      staffName: "", // 작성자
      boardContents: "", // 내용
      value: "", // 제목
      sdate: "", // 시작 기간
      edate: "", // 종료 기간
    },
    isAdminView: false,
  }),

  mounted() {
    this.getBoardList();
    //2022-12-27 cian님 등록버튼 등록자들만 보이도록 수정
    if(store.state.userInfo.auth == 'MASTER' || store.state.userInfo.auth == 'ADMIN'){
      this.isAdminView = true;
    }
  },

  unmounted() {},

  methods: {
    // 이전 페이징
    prevPaging() {
      if (this.page === 1) {
        return false;
      } else {
        this.page -= 1;
        this.getBoardList();
      }
    },
    paging(index) {
      this.page = index;
      this.getBoardList();
    },

    // 현재 페이지의 페이지 범위 계산
    calcMaxPaging() {
      if (this.maxPagingCount > this.pagePart * 10) {
        this.maxPagePart = this.pagePart * 10;
      } else {
        this.maxPagePart = this.maxPagingCount;
      }
      var pagingArray = [];
      for (var i = (this.pagePart - 1) * 10 + 1; i <= this.maxPagePart; i++) {
        pagingArray.push(i);
      }
      return pagingArray;
    },

    // 다음 페이징
    nextPaging() {
      if (this.page === this.maxPagingCount) {
        return false;
      } else {
        this.page += 1;
        this.getBoardList();
      }
    },

    // 공지 상세 페이지로 이동
    moveToNoticeDetail(idx) {
      this.$router.push(`/origin/notice_detail/${idx}`);
    },

    // 공지 목록 페이지롤 이동
    moveToNoticeRegister() {
      this.$router.push("/origin/notice/register");
    },
    // 공지 리스트 출력
    getBoardList(idx = -1) {
      var param = {
        page: this.page,
        pagesize: this.pagesize,
      };
      this.loading = true;

      this.axios
        .get(`/api/v1/board/${idx}`, { params: param })
        .then((res) => {
          if (res.data.err === 0) {
            this.boardList = res.data.boardList;
            if (res.data.boardListCount) {
              this.listLength = res.data.boardListCount;
            }
            this.maxPagingCount = Math.ceil(this.listLength / this.pagesize);
            this.maxPagePart = Math.ceil(this.maxPagingCount / 10);
            this.pagePart = Math.ceil(this.page / 10);
          } else {
            this.boardList = [];
            // alert(res.data.err_msg);
          }
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("id");
          alert("세션이 만료되어 로그인 페이지로 이동합니다.");
          this.$router.push("/login");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSearch(idx = -1) {
      let param = {
        staffName: this.param.staffName,
        boardContents: this.param.boardContents,
        value: this.param.value,
        sdate: this.param.sdate,
        edate: this.param.edate,
      };

      this.axios
        .get(`/api/v1/board/${idx}`, { params: param })
        .then((res) => {
          this.boardList = res.data.boardList;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  // 검색조건 유지
  // sessionStorage.setItem('admin_consult_pageNum', this.pageNum)
  // sessionStorage.setItem('admin_consult_center', this.idxCrmCenter)
  // sessionStorage.setItem('admin_consult_gubun', this.idxCrmMetaProduct)
  // sessionStorage.setItem('admin_consult_value', this.value)

  //     this.axios.get(`/api/v1/board/:${idx}`, {
  //             params: params
  //         })
  //         .then(res => {
  //             console.log('res.data', res.data)
  //             if (res.data.err === 0) {
  //                 this.reportList = res.data.reportList

  //                 if (res.data.reportListCount) {
  //                     this.listLength = res.data.reportListCount
  //                 }
  //                 this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
  //                 this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
  //                 this.pagePart = Math.ceil(this.pageNum / 10)
  //             } else {
  //                 //alert('검색 결과가 없습니다.')
  //                 this.reportList = []
  //             }
  //         })
  // },
  // 공지사항 목록
};
</script>

<style lang="scss" scoped>
.btn-cal {
  width: 30px;
  border: 1px solid #e3e5ed;
  border-left-width: 0;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: url(~@/assets/images/ico_cal.png) center center no-repeat;
}
</style>
